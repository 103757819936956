<template>
  <multiselect
    v-if="visible"
    v-model="selected"
    :placeholder="placeholder"
    :internal-search="false"
    :options="results"
    :searchable="true"
    :loading="isLoading"
    :options-limit="20"
    :max-height="600"
    :custom-label="customLabel"
    @search-change="asyncFindApi"
    @select="onSelect"
    @open="hideOthers"
    @close="showOthers"
  />
</template>

<script>
import multisearch from '@/mixins/multisearch';
import debounce from '@/mixins/debounce';

export default {
  mixins: [
    multisearch, //
    debounce, //
  ],

  data() {
    return {
      type: 'artist',
      placeholder: 'Artist Search',
    };
  },

  methods: {
    onSelect(option) {
      // console.log('onSelect', option);
      Bus.$emit('songs:fetch', {
        artist: option.artist,
      });
      Bus.$emit('searches:clear', 'artist');
    },

    customLabel(option) {
      // console.log('customLabel', option);
      return $('<div/>').html(option.artist).text();
    },

    asyncFindApi(query) {
      this.debounce(() => {
        // console.log('asyncFindApi.query', query);
        this.results = [];
        this.isLoading = true;

        this.$http.get('/api/v1/search/artists', { params: { query } }).then((res) => {
          // console.log('res.data', res.data);
          this.results = res.data;
          this.isLoading = false;
        });
      }, 200);
    },
  },
};
</script>
